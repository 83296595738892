.ImgDecode {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black
  }
  .selectFileButton:hover {
    background-color: #4caf50; /* A shade darker green for hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* A simple box shadow for depth */
  }
  
  .selectFileButton:active {
    background-color: #388e3c; /* Even darker shade of green for active state */
    box-shadow: none; /* Remove box shadow for a pressed effect */
    transform: translateY(2px); /* Slight movement to simulate pressing */
  }
  