.login-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;  /* Dark text color */
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;  /* Border added for visibility */
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3; /* The hover color you'd like */
  transition: background-color 0.3s ease-in-out; /* Smooth transition effect */
}


.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Additional styling */
.login-navbar h3 {
  color: #333;  /* Dark text color */
}

.login-navbar img {
  margin-bottom: 10px;
}
