body {
    background: linear-gradient(135deg, #2eb0c321 0%, #3b6971 100%);
    font-family: 'Arial', sans-serif;
    height: 100vh;
    margin: 0;
    color: #555;
}

body, html {
    margin: 0;
    padding: 0;
}

.container-component {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    transition: transform 0.2s;
}

.container-component:hover {
    transform: scale(1.03);
}